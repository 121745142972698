import React from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface TooltipProps {
  // Message to display
  text: string;
  // The node to hover over
  children: React.ReactNode;
  // Additional classnames to apply
  className?: string;
}

export interface TooltipIconProps {
  // Message to display
  text: string;
  // Icon from FontAwesome
  icon: IconProp;
  // Color for icon
  color: string;
  // Title for icon
  title: string;
  // Additional classnames to apply
  className?: string;
  // Size to apply to the icon
  size?: FontAwesomeIconProps['size'];
}

export function Tooltip({ text, children, className }: TooltipProps) {
  return (
    <OverlayTrigger
      overlay={
        <BootstrapTooltip id="tooltip-disabled">{text}</BootstrapTooltip>
      }
    >
      <span data-testid="tooltip" className={className}>
        {children}
      </span>
    </OverlayTrigger>
  );
}

export function TooltipIcon({
  text,
  icon,
  title,
  color,
  className,
  size = 'xs',
}: TooltipIconProps) {
  return (
    <OverlayTrigger
      overlay={
        <BootstrapTooltip id="tooltip-icon-disabled">{text}</BootstrapTooltip>
      }
    >
      <span data-testid="tooltip-icon" className={className}>
        <FontAwesomeIcon
          icon={icon as any}
          title={title}
          color={color}
          size={size}
        />
      </span>
    </OverlayTrigger>
  );
}
