import { styled } from '../../settings/theme';
import { BaseBox } from '../layout/BaseBox';

export const BigBox = styled(BaseBox)`
  padding: 1rem;

  & > .box-inner {
    transition: all ease-in-out 0.1s;
    background: ${({ theme }) => theme.color.white};
    padding: 1.2rem;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    box-shadow: ${({ theme }) => theme.elevation.primary};

    &.selected {
      background: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};
    }
  }
  & .box-icon {
    display: block;
    font-size: 3rem;
  }
  & .box-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem;
    display: inline-block;
  }
`;

export const MediumBox = styled(BaseBox)`
  & > .box-inner {
    cursor: pointer;
    padding: 1.1rem 1rem;
    transition: all ease-in-out 0.1s;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    box-shadow: ${({ theme }) => theme.elevation.primary};

    &.selected {
      background: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};
    }

    &:hover {
      transform: scale(1.02);
      box-shadow: ${({ theme }) => theme.elevation.secondary};
    }
  }
  & .box-icon {
    font-size: 1.2rem;
  }
  & .box-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
