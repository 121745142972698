import { Strategy } from './validator.type';
import {
  isISO8601,
  isEmail,
  isURL,
  isLength,
  isPhoneNumber,
  isUSZipCode,
  isMoveSizeType,
} from './validator.common';
import validator from 'validator';

export const isPhoneNumberStrategy: Strategy = (value, options) => {
  if (!isPhoneNumber(value)) {
    return options?.errors?.isPhoneNumber || 'Invalid US phone';
  }
};

export const isLengthStrategy: Strategy = (value, options) => {
  if (!isLength(value, options as validator.IsLengthOptions)) {
    return options?.errors?.isLength || 'Invalid length';
  }
};

export const isUrlStrategy: Strategy = (value, options) => {
  if (!isURL(value)) {
    return options?.errors?.isURL || 'Must be a valid URL';
  }
};

export const isEmailStrategy: Strategy = (value, options) => {
  if (!isEmail(value)) {
    return options?.errors?.isEmail || 'Invalid email';
  }
};

export const isUSZipCodeStrategy: Strategy = (value, options) => {
  if (!isUSZipCode(value)) {
    return options?.errors?.isPostalCode || 'Invalid US zip code';
  }
};

export const isISO8601Strategy: Strategy = (value, options) => {
  if (!isISO8601(value)) {
    return options?.errors?.isISO8601 || 'Invalid ISO date';
  }
};

export const isMoveSizeStrategy: Strategy = (value, options) => {
  if (!isMoveSizeType(value)) {
    return options?.errors?.isMoveSizeType || 'Invalid move size';
  }
};
