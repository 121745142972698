// Custom validation strategies
import validator from 'validator';
import { Validator, IsTypeValidatorOptions } from './validator.type';
import { moveSizeTypes } from '../types/move-size.type';

// import and export here for convenience/keep everything in one place
export const { isLength, isISO8601, isEmail, isURL } = validator;

// Generic type validator
export const isType: Validator<IsTypeValidatorOptions<any>> = (
  value,
  options,
) => {
  return value in options!.type;
};

// Generic RegEx exact match validator
export const isRegexExactMatch = (value: string, regexp: string | RegExp) => {
  const res = value.match(regexp);
  return res && res[0] && res[0] === value;
};

export const isEmpty: Validator<validator.IsEmptyOptions> = (
  value: string,
  options,
) => {
  return validator.isEmpty(value, options);
};

export const isPhoneNumber: Validator = (value: string) => {
  return /^(\+1\d{10})$/.test(value);
};

export const isUSZipCode: Validator = (value: string): boolean => {
  const zipcodes = require('zipcodes');
  const lookup = zipcodes.lookup(value);
  return lookup && lookup.country === 'US';
};

export const isMoveSizeType: Validator = (value: string) => {
  return isType(value, { type: moveSizeTypes });
};
