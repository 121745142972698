import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ErrorMessageProps {
  // The error message
  error?: string;
}

export function ErrorMessage({ error }: ErrorMessageProps) {
  return (
    <div className="text-b d-inline-block">
      {error && (
        <small data-testid="form-error-message" className="text-danger pl-2">
          <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />
          <strong className="pl-1">{error}</strong>
        </small>
      )}
    </div>
  );
}
