import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface BoxProps {
  title?: string;
  icon?: IconDefinition | IconProp;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  onMouseDown?: (e: React.MouseEvent) => void;
  innerClassName?: string;
}

export const BaseBox = ({
  title,
  icon,
  children,
  innerClassName,
  ...props
}: BoxProps) => {
  return (
    <div role="button" {...props}>
      <div
        className={classnames(
          'container d-flex align-items-center text-left box-inner',
          innerClassName,
        )}
      >
        {icon && (
          <div className="mr-3">
            <FontAwesomeIcon icon={icon as any} className="box-icon" />
          </div>
        )}
        {title && (
          <div className="">
            <span className="box-title">{title}</span>
          </div>
        )}
        {children && children}
      </div>
    </div>
  );
};
