import React from 'react';
import { styled, Theme } from '../../settings/theme';
import { Tooltip } from './Tooltip';
import classnames from 'classnames';

export interface LabelProps {
  // Id for htmlFor property
  id?: string;
  // Will display an outline circle before label
  required?: boolean;
  // The name and value for the label
  label?: string;
  // Disable flag
  disabled?: boolean;
  // Optional class name
  className?: string;
}

const RequiredIcon = styled.span`
  padding-right: 0.4rem;
  color: ${({ theme }) => theme.color.red};
`;

interface SpanProps {
  theme: Theme;
  disabled: boolean;
}

const Span = styled.span`
  letter-spacing: 0.1em;
  color: ${({ theme, disabled }: SpanProps) =>
    disabled ? theme.color.lightGrey : theme.color.primary};
`;

export function Label({
  id,
  required,
  label,
  disabled = false,
  className,
}: LabelProps) {
  return (
    <label htmlFor={id} className={classnames('mb-2', className)}>
      {required && !disabled && (
        <Tooltip text={`This field is required`}>
          <RequiredIcon className="text-danger">*</RequiredIcon>
        </Tooltip>
      )}
      <Span
        className={classnames(
          'text-uppercase font-weight-bold small',
          className,
        )}
        disabled={disabled}
      >
        {label}
      </Span>{' '}
    </label>
  );
}
