import { useState } from 'react';
import {
  FieldError,
  FieldValidator,
  ValidationRule,
  ValidationSchema,
} from './validator.type';
import { isEmpty } from './validator.common';

const validateAndGenerateMessage = (
  rule: ValidationRule,
  value: string | number = '', // serialized values only. set empty string for undefined cases.
) => {
  const valueAsString = value === null ? '' : String(value);
  let errorMessage = '';
  if (isEmpty(valueAsString) && rule.required) {
    errorMessage = 'Required';
  } else if (!isEmpty(valueAsString)) {
    const { strategies = [], options } = rule;
    for (let strategy of strategies) {
      const error = strategy(valueAsString, options);
      if (!!error) {
        // break if there is an error message and return the current error
        errorMessage = error;
        break;
      } else {
        // reset the error message to empty string
        errorMessage = '';
      }
    }
  }

  return errorMessage;
};

export const validateSchema = (
  validationObject: object,
  validationSchema: ValidationSchema,
): FieldError => {
  // TODO: make types generic
  type FieldKey = keyof typeof validationObject;
  type SchemaKey = keyof typeof validationSchema;
  const errorMessages = {} as FieldError;
  for (let key in validationSchema) {
    if (validationSchema.hasOwnProperty(key)) {
      const error = validateAndGenerateMessage(
        validationSchema[key as SchemaKey],
        validationObject[key as FieldKey],
      );
      if (error !== '') {
        errorMessages[key] = error;
      }
    }
  }
  return errorMessages;
};

// useValidation hook
export const useValidation = (
  validationObject: object,
  validationSchema: ValidationSchema,
) => {
  const [validationErrorsById, setValidationErrorsById] = useState<FieldError>(
    {},
  );

  // helper function for validating the form when a user clicks "continue/submit/next"
  // a sort of a last check if the validation didn't work (for some weird reason)
  const validateAll = () => {
    const errors = validateSchema(validationObject, validationSchema);
    setValidationErrorsById(errors);
    return !Object.keys(errors).length;
  };

  // this will be passed down to each input component so
  // that they can control their own validation.
  // notice that this is a double arrow function (closure)
  const fieldValidator = (
    id: string,
    value: string | number | undefined,
  ): FieldValidator => {
    // because this function is initialized in a parent component, we control the state
    // therefore the next time it can be called without any params as the state is cached (see InputField)
    // NOTE: do not add useCallback in this method definition (only where this method has been called)
    return {
      isValid: () => {
        return !validateAndGenerateMessage(validationSchema[id], value);
      },
      validate: () => {
        setValidationErrorsById((prevState: FieldError) => ({
          ...prevState,
          [id]: validateAndGenerateMessage(validationSchema[id], value),
        }));
      },
      // return a removeError function to delete the error when a user refocuses on a field
      // you can see how this is used in InputField
      clear: () => {
        setValidationErrorsById((prevState: FieldError) => ({
          ...prevState,
          [id]: undefined,
        }));
      },
    };
  };

  return {
    validateAll,
    fieldValidator,
    validationErrorsById,
  };
};
