import React, { HTMLProps } from 'react';
import { Rating } from './Rating';
import { BigBox } from '../styled/Box.styled';
import { styled } from '../../settings/theme';

interface TestimonialProps extends HTMLProps<HTMLDivElement> {
  name: string;
  score: number;
  children: React.ReactNode;
  location: string;
}

const Quote = styled.span`
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: ${({ theme }) => theme.fontSize.primary};
  margin: 0 0.3rem;
`;

export const Testimonial = ({
  name,
  score,
  location,
  children,
  className,
}: TestimonialProps) => {
  return (
    <BigBox className={className}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <strong className="mb-0">{name}</strong>
          <Rating score={score} />
        </div>

        <small className="mb-3">{location}</small>

        <span className="text-left">
          <Quote>„</Quote>
          {children}
          <Quote>“</Quote>
        </span>
      </div>
    </BigBox>
  );
};
