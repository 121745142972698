export const moveSizeTypes = {
  ONE_BEDROOM: {
    key: 'ONE_BEDROOM',
    name: 'One Bedroom',
  },
  TWO_BEDROOMS: {
    key: 'TWO_BEDROOMS',
    name: 'Two Bedrooms',
  },
  THREE_BEDROOMS: {
    key: 'THREE_BEDROOMS',
    name: 'Three Bedrooms',
  },
  FOUR_PLUS_BEDROOMS: {
    key: 'FOUR_PLUS_BEDROOMS',
    name: '4+ Bedrooms',
  },
  COMMERCIAL: {
    key: 'COMMERCIAL',
    name: 'Office / Commercial',
  },
  STUDIO: {
    key: 'STUDIO',
    name: 'Studio',
  },
} as const;
