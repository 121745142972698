import React, { Fragment, HTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar as Star,
  faStarHalfAlt as StarHalf,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { styled } from '../../settings/theme';

export interface RatingProps extends HTMLProps<HTMLDivElement> {
  score: number;
}

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.color.red};
  font-size: 1.1rem;
`;

export const Rating = ({ score, ...props }: RatingProps) => {
  const getStars = () => {
    switch (score) {
      case 1:
        return [Star, EmptyStar, EmptyStar, EmptyStar, EmptyStar];
      case 1.5:
        return [Star, StarHalf, EmptyStar, EmptyStar, EmptyStar];
      case 2:
        return [Star, Star, EmptyStar, EmptyStar, EmptyStar];
      case 2.5:
        return [Star, Star, StarHalf, EmptyStar, EmptyStar];
      case 3:
        return [Star, Star, Star, EmptyStar, EmptyStar];
      case 3.5:
        return [Star, Star, Star, StarHalf, EmptyStar];
      case 4:
        return [Star, Star, Star, Star, EmptyStar];
      case 4.5:
        return [Star, Star, Star, Star, StarHalf];
      case 5:
        return [Star, Star, Star, Star, Star];
      default:
        return [EmptyStar, EmptyStar, EmptyStar, EmptyStar, EmptyStar];
    }
  };

  const stars = getStars();

  return (
    <div {...props}>
      {stars.map((icon, index) => (
        <Fragment key={index}>
          <Icon icon={icon as any} />{' '}
        </Fragment>
      ))}
    </div>
  );
};
