import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { FormState, setField, SetFieldPayload } from '../form.slice';

export const useForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const form = useSelector((state: RootState) => state.form);

  const setFormField = ({ formId, fieldId, value }: SetFieldPayload) => {
    dispatch(
      setField({
        formId,
        fieldId,
        value,
      }),
    );
  };

  const getForm = (formId: keyof FormState) => form[formId];

  return { setFormField, getForm };
};
